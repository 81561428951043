import Footer from "./Footer";
import MenuBar from "./MenuBar";
import "./TeamPage.css";
import NikolaMilosevic from './Nikola-Milosevic.png';
import AdelaLjajic from './adelaljajic2.jpg';
import MilosKosprdic from './MK_sajt.jpg';
import PetarStevanovic from './petarstevanovic.JPG';
import AngelaPupovac from './angelapupovac.jpg';
import BojanaBasaragin from './bojanabasaragin3.jpg';
import DarijaMedvecki from './Darija-Medvecki_manja-eng.jpg';
import NatasaRadmilovic from './natasaradmilovic.jpg';

import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import linkedin from "./linkedin3.svg";


/*const teamMembers = [
    { id: "nikola-milosevic", linkedin: "https://www.linkedin.com/in/nikolamilosevic1986/", name: "Nikola Milošević", status: "Team lead", desc: "The initiator and a team lead in the VerifAI project, currently employed at Bayer A.G; Senior computational scientist with deep expertise in natural language processing and machine learning...", image: NikolaMilosevic, longDesc: "Nikola Milosevic is a senior computational scientist with deep expertise in natural language processing and machine learning. He holds a PhD in Computer Science from the University of Manchester, where he focused on information extraction and mining from tables in biomedical and clinical scientific literature. With extensive experience in both academia and industry, Milosevic has worked on various projects involving web crawling, machine learning, and advanced NLP techniques, such as information extraction, text classification, text summarization, and lately is very involved in generative AI. He is also interested in NLP for low-resourced languages such as Serbian, or applying NLP techniques in other fields, such as biomedicine, or malware analysis. Nikola Milosevic is currently employed at Bayer A.G., as a computational scientist. He is the initiator and a team lead in the VerifAI project. Nikola has a long history of open-source involvement, dating back to 2012, when he became involved with the Open Web Application Security Project, but he also open-sourced the ority of his scientific research. "},
    { id: "adela-ljajic", linkedin: "https://www.linkedin.com/in/adelacrnisanin/?originalSubdomain=rs", name: "Adela Ljajić", status: "Team member", desc: "Description text", image: AdelaLjajic},
    { id: "milos-kosprdic", linkedin: "https://www.linkedin.com/in/milo%C5%A1-ko%C5%A1prdi%C4%87/?originalSubdomain=rs",  name: "Miloš Košprdić", status: "Team member", desc: "Researcher at The Institute for Artificial Intelligence of Serbia; Senior Associate at Linguistics Department at Petnica Science Center...", image: MilosKosprdic, longDesc: "Researcher at The Institute for Artificial Intelligence of Serbia; Senior Associate at Linguistics Department at Petnica Science Center..."},
    { id: "bojana-basaragin", linkedin: "https://www.linkedin.com/in/bojana-basaragin/?originalSubdomain=rs", name: "Bojana Bašaragin", status: "Team member", desc: "Senior Researcher in the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Leverages language technologies in healthcare and industry, with a particular focus on language models...", image: BojanaBasaragin, longDesc: "Bojana Bašaragin earned her Ph.D. in Computational Linguistics from the Faculty of Philology in Belgrade, specializing in syntax and natural language processing. Her significant contributions in this field include the creation and enhancement of language resources for Serbian, encompassing WordNet, a dictionary of synonyms, parallel corpora, rule-based parsers, and formal grammars. At the Institute, she serves as a Senior Researcher in the Human-Computer Interaction research group. In her projects, she leverages language technologies in healthcare and industry, with a particular focus on language models. Driven by a belief in the transformative power of AI, she is passionate about applying natural language processing techniques in healthcare to improve lives and advance biomedical research."},
    { id: "darija-medvecki", linkedin: "https://www.linkedin.com/in/darija-medvecki-b27468ab/?originalSubdomain=rs", name: "Darija Medvecki", status: "Team member", desc: "Research assistant at the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Specializing in the field of natural language processing and a marketing specialist...", image: DarijaMedvecki, longDesc: "Darija Medvecki is a young researcher specializing in the field of natural language processing and a marketing specialist. She is currently pursuing a Ph.D. in artificial intelligence at the Faculty of Technical Sciences in Novi Sad. As a research assistant at the Human-Computer Interaction research group at the Institute, she contributes to multiple projects across diverse fields, including the biomedical domain, customer service, and social media. Her work encompasses sentiment analysis, topic modeling, and named entity recognition, as well as contributing to the development of Serbian language resources. Her primary focus is on data collection, preparation, and leveraging large language models."},
];*/

const teamMembers = [
    { id: "nikola-milosevic", linkedin: "https://www.linkedin.com/in/nikolamilosevic1986/", name: "Nikola Milošević", status: "Team lead", desc: "The initiator and a team lead in the VerifAI project, currently employed at Bayer A.G; Senior computational scientist with deep expertise in natural language processing and machine learning...", image: NikolaMilosevic, longDesc: "Nikola Milosevic is a senior computational scientist with deep expertise in natural language processing and machine learning. He holds a PhD in Computer Science from the University of Manchester, where he focused on information extraction and mining from tables in biomedical and clinical scientific literature. With extensive experience in both academia and industry, Milosevic has worked on various projects involving web crawling, machine learning, and advanced NLP techniques, such as information extraction, text classification, text summarization, and lately is very involved in generative AI. He is also interested in NLP for low-resourced languages such as Serbian, or applying NLP techniques in other fields, such as biomedicine, or malware analysis. Nikola Milosevic is currently employed at Bayer A.G., as a computational scientist. He is the initiator and a team lead in the VerifAI project. Nikola has a long history of open-source involvement, dating back to 2012, when he became involved with the Open Web Application Security Project, but he also open-sourced the ority of his scientific research. "},
    { id: "bojana-basaragin", linkedin: "https://www.linkedin.com/in/bojana-basaragin/", name: "Bojana Bašaragin", status: "Team member", desc: "Senior Researcher in the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Leverages language technologies in healthcare and industry, with a particular focus on language models...", image: BojanaBasaragin, longDesc: "Bojana Bašaragin earned her Ph.D. in Computational Linguistics from the Faculty of Philology in Belgrade, specializing in syntax and natural language processing. Her significant contributions in this field include the creation and enhancement of language resources for Serbian, encompassing WordNet, a dictionary of synonyms, parallel corpora, rule-based parsers, and formal grammars. At the Institute, she serves as a Senior Researcher in the Human-Computer Interaction research group. In her projects, she leverages language technologies in healthcare and industry, with a particular focus on language models. Driven by a belief in the transformative power of AI, she is passionate about applying natural language processing techniques in healthcare to improve lives and advance biomedical research."},
    { id: "darija-medvecki", linkedin: "https://www.linkedin.com/in/darija-medvecki-b27468ab/", name: "Darija Medvecki", status: "Team member", desc: "Research assistant at the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Specializing in the field of natural language processing and a marketing specialist...", image: DarijaMedvecki, longDesc: "Darija Medvecki is a young researcher specializing in the field of natural language processing and a marketing specialist. She is currently pursuing a Ph.D. in artificial intelligence at the Faculty of Technical Sciences in Novi Sad. As a research assistant at the Human-Computer Interaction research group at the Institute, she contributes to multiple projects across diverse fields, including the biomedical domain, customer service, and social media. Her work encompasses sentiment analysis, topic modeling, and named entity recognition, as well as contributing to the development of Serbian language resources. Her primary focus is on data collection, preparation, and leveraging large language models."},
    { id: "adela-ljajic", linkedin: "https://www.linkedin.com/in/adelacrnisanin/?originalSubdomain=rs", name: "Adela Ljajić", status: "Team member", desc: "Research Associate at The Institute for Artificial Intelligence Research and Development of Serbia – Human Computer Interaction group; Focusing on transformer-based architecture like BERT and GPT...", image: AdelaLjajic, longDesc: "Adela Ljajić is a data scientist with natural language processing and machine learning expertise. She earned her Ph.D. in Electrical Engineering and Computer Science in the research domain of Artificial Intelligence, where she developed negation detection and sentiment analysis methods for the low-resourced Serbian language. Previously, she has held roles as a teaching assistant and research assistant in academia, worked as a freelancer on Upwork and collaborated with NPL group of the Allen AI Institute in a research study. Currently, she is Research Associate at The Institute for Artificial Intelligence Research and Development of Serbia – Human Computer Interaction group, where she focuses on transformer-based architecture like BERT and GPT, applying them to named entity recognition, topic modeling, sentiment analysis and semantic search. For the VerifAI project, she serves as the coordinator from the Institute's side and is also responsible for semantic indexing in information retrieval components."},
    { id: "milos-kosprdic", linkedin: "https://www.linkedin.com/in/milo%C5%A1-ko%C5%A1prdi%C4%87/",  name: "Miloš Košprdić", status: "Team member", desc: "Researcher at The Institute for Artificial Intelligence of Serbia; Senior Associate at Linguistics Department at Petnica Science Center...", image: MilosKosprdic, longDesc: "Miloš Košprdić is a linguist and mathematician currently pursuing a Ph.D. in Artificial Intelligence at the University of Belgrade. His research focuses on natural language processing and large language models, including sentiment analysis, topic modeling, information extraction, semantic search, and reducing hallucinations in generative language models. Miloš has led linguistics seminars and served as a lecturer and educator at the Petnica Science Center for many years. He advocates for an interdisciplinary approach in science and values heuristic methods and the zone of proximal development in education. Currently, Miloš is a young researcher at the Institute for Artificial Intelligence Research and Development of Serbia, within the Human-Computer Interaction group. He focuses on transformer-based architectures such as BERT and Llama, applying them primarily to named entity recognition and semantic search. For the VerifAI project, he is responsible for developing the verification module and is also involved in lexical and semantic search for the information retrieval component."},
    { id: "angela-pupovac", linkedin: "https://www.linkedin.com/in/angela-pupovac-20a751226/", name: "Angela Pupovac", status: "Team member", desc: "Community Manager for the VerifAI project; a graduating Philosophy student at the Faculty of Philosophy, University of Belgrade...", image: AngelaPupovac, longDesc: "Angela has a keen interest in the philosophical exploration of artificial intelligence as part of her academic and professional pursuits. Her primary focus in philosophy lies in the areas of ethics, philosophy of science, and philosophy of mind. In her AI research, Angela is particularly interested in AI alignment and AI-based cognition. She aims to contribute to the responsible development and deeper understanding of intelligent systems through an interdisciplinary approach. At VerifAI, Angela is responsible for building and nurturing the project’s community."},
    { id: "petar-stevanovic", linkedin: "https://www.linkedin.com/in/petar-stevanovi%C4%87-1b25852b0/", name: "Petar Stevanović", status: "Team member", desc: "Intern at The Institute for Artificial Intelligence of Serbia, currently pursuing a master's degree in philosophy at the University of Belgrade; Interested in graphic design and visual marketing...", image: PetarStevanovic, longDesc: "Petar Stevanović is a young philosopher with a passion for graphic design and visual marketing. He is currently pursuing a master’s degree in philosophy at the University of Belgrade. His primary academic interests include normative and applied ethics, political philosophy, and the philosophy of science. Regarding artificial intelligence, Petar is particularly focused on the relationship between AI and neuroscience within applied ethics. On the VeriAI project, he is responsible for establishing the project’s visual identity and redesigning the VeriAI website and application. "},
    { id: "natasa-radmilovic", linkedin: "https://www.linkedin.com/in/nata%C5%A1a-radmilovi%C4%87-ab88582b7/",  name: "Nataša Radmilović", status: "Team member", desc: "Intern at The Institute for Artificial Intelligence of Serbia; Currently a fourth-year student of Computing and Automation at the Faculty of Technical Sciences, Novi Sad, with an interest in web development...", image: NatasaRadmilovic, longDesc: "Nataša Radmilović is an intern at the Institute of Artificial Intelligence of Serbia, where she works on the frontend development of the VerifAI web application and website. She is currently pursuing a bachelor's degree at the Faculty of Technical Sciences, Novi Sad. Although her work focuses on web development, she has a strong interest in both artificial intelligence and informational systems."},
];

function TeamPage()
{
    const [openDescriptions, setOpenDescriptions] = useState({});
    const descriptionRefs = useRef({});
    const toggleDescription = (id) => {
        
       
        setOpenDescriptions(prevState => ({
            ...prevState,
            [id]: prevState[id] === 'fade-in' ? 'fade-out' : 'fade-in' // Toggle between 'fade-in' and 'fade-out'
        }));

        

    };

    
    
        
    
    return (
        <div className="PageBackground">
        <div className="TeamPageContainer">
           <div className="MenuBarDiv">
                <MenuBar/>
               </div>

            <div className="TeamContainer2">
                <div className="TeamContainer">
                    <h1>Team</h1>
                    <p>Our team is composed of NLP experts from Bayer Pharma R&D Data Sciences and AI department, and NLP group members from the Institute for AI Research and Development of Serbia. Current team setup is:</p>
                    <div className="GalleryContainer">
                    <div className="TeamGallery">
                       {teamMembers.map((teamMember) => ( <div key={teamMember.id} className="imageContainer" onClick={() => toggleDescription(teamMember.id)}>
                            <img src={teamMember.image}/>
                            <div className="overlay"></div>
                            <div className="imageText">
                                <h1 className="image-h1">{teamMember.name}</h1>
                                <h2 className="image-h2">{teamMember.status}</h2>
                            </div>
                            
                         {openDescriptions[teamMember.id] && (   <div  className={`description ${openDescriptions[teamMember.id] || 'fade-out'}`} ref={el => descriptionRefs.current[teamMember.id] = el}>
                            <div className='memberLinkedIn'><h1 className="image-h1">{teamMember.name}</h1><Link to={teamMember.linkedin} target="_blank" rel="noopener noreferrer"><button className='LinkedInButton shrunk'><img src={linkedin}/></button></Link></div> 
                            <h2 className="image-h2">{teamMember.status}</h2>
                             <div className="description-container">   <div className="description-text">
                                    <p>{teamMember.desc} <a href={"/member?id="+ teamMember.id} className="viewMore">View more</a></p>
                                </div></div>
                               
                            </div>
                       
                            )}


                        </div>
                        ))}
                  
                        
                    </div>
                    </div>
                </div>
                
            </div>
       
            
               <div className="FooterDiv">
                    <Footer/>
                </div>
           
        </div>
        </div>
    )
}

export default TeamPage;