import Footer from "./Footer";
import MenuBar from "./MenuBar";
import "./SignInPage.css"

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
function SignInPage()
{
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
   
    


    return (
        <div className="SignInPageContainer">
           <div className="MenuBarDiv">
                <MenuBar/>
               </div>

               <div className="SignInContent">
               <div className="login-form">
            <h1>Sign in</h1>
            <form className="formClass">
                <input className="formInput" type="text" placeholder="Username"/>
                <input className="formInput" type="password" placeholder="Password"/>
                <button class="center-button">Log In</button>
            </form>
            
        </div>
               </div>

               <div className="FooterDiv">
                    <Footer/>
                </div>
            
           
        </div>
    )
}

export default SignInPage;