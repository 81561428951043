import Footer from "./Footer";
import MenuBar from "./MenuBar";
import "./ContactPage.css";
import { Link } from "react-router-dom";
import facebook from './facebook.svg';
import linkedin from './linkedin3.svg';
import instagram from './instagram2.svg';
import twitter from './twitter.svg';
import github from './github2.svg';
import { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';


function ContactPage()
{

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleSubmit = (e) => 
    {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);

        fetch(form.action, {method: 'POST', body: data, headers: {'Accept': 'application/json'},})
        .then((response) => response.json()).then((data) => {console.log('Success:', data);})
        .catch((error) => {console.error('Error:', error);});
    };

    useEffect(() => {
        document.title = "Verif.ai - Contact us!";
      }, []);
      const meta = {
        title: "Verif.ai Contact",
        description: "Contact Verif.ai project",
        canonical: "https://verifai-project.com/contact",
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
      }
    
    return (
        <div className="PageBackground">
            <DocumentMeta {...meta} />
        <div className="ContactPageContainer">
           <div className="MenuBarDiv">
                <MenuBar/>
               </div>


               <div className="ContactContainer2">
                <div className="ContactContainer">
               <div className="ContactContent">
                  <div className="ContactDiv">
                    <h1>Contact</h1>
                    <p>In case of any questions and queries, you can contact us via email at <targe><span style={{ color: '#005d97', cursor: 'pointer' }}>verif.ai.project@gmail.com </span></targe>.</p>
                    <p>VerifAI: In case, you would like us to consult on deploying solution based on Verif.ai or would like to use the developed solution under different licensing terms then provided by default, please get in touch with us.</p>
                  </div>

                  <div className="SubscribeSocial">
                  <div className="SubscribeDiv">
                    <h2>Subscribe to our Newsletter</h2>
                 
                 
                    <form className="SubscribeForm" action="https://gmail.us13.list-manage.com/subscribe/post?u=34c8308d0ab65e7b96e0dc5dc&id=330c16a5c6&f_id=00a105e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" >
                        <div id="mc_embed_signup_scroll">
                               <div className="fieldGroup"> 
                               <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                           
                                <div className="mc-field-group">
                                    
                                    <input type="email" name="EMAIL" id="emailInput" className="formInput" id="mce-EMAIL" required defaultValue="" placeholder="E-mail *"></input></div>
                                <div className="mc-field-group"><input type="text" name="FNAME" className="formInput" id="mce-FNAME" defaultValue="" placeholder="First name"/></div>
                                <div className="mc-field-group"><input type="text" name="LNAME" className="formInput" id="mce-LNAME" defaultValue="" placeholder="Last name" /></div>
                                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="formButton" defaultValue="Subscribe" />
                                </div>
                                    <div id="mce-responses" className="clear foot">
                                     <div className="response" id="mce-error-response" style={{display: 'none'}} />
                                     <div className="response" id="mce-success-response" style={{display: 'none'}} />
                                        </div>
                                    <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
                                                      /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                                        <input type="text" name="b_34c8308d0ab65e7b96e0dc5dc_330c16a5c6" tabIndex={-1} defaultValue />
                                    </div>
                                            <div className="optionalParent">
                                         <div className="clear foot">
                              
                                                    </div>
                                                     </div>
                                                     </div>
                                                </form>
                                                <Link to ="https://mailchimp.com/solutions/email-marketing-platform/?utm_source=freemium_newsletter&utm_medium=email&utm_campaign=referral_marketing&aid=34c8308d0ab65e7b96e0dc5dc&afl=1" target="_blank" rel="noopener noreferrer"><img className="mailChimpImg" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"/></Link>
                  </div>
                  <div className="SocialMediaDiv">
                    <h2>Social media</h2>
                    <p>You can follow us on social media: </p>
                    <div className="socials">
                        <Link to ="https://www.facebook.com/VerifAIsystem/" target="_blank" rel="noopener noreferrer"><button className="socialButton">
                            <div className="buttonContent">
                                <img src = {facebook}/>
                                </div></button></Link>
                        
                        <Link to ="https://www.instagram.com/verifai_system/" target="_blank" rel="noopener noreferrer"><button className="socialButton">
                            <div className="buttonContent">
                                <img src = {instagram}/>
                                </div></button></Link>
                        
                        <Link to ="https://x.com/verifai_system" target="_blank" rel="noopener noreferrer"><button className="socialButton">
                            <div className="buttonContent">
                                <img src = {twitter}/>
                                </div></button></Link>
                        
                         <Link to ="https://www.linkedin.com/company/verif-ai-project/posts/?feedView=all" target="_blank" rel="noopener noreferrer"><button className="socialButton">
                            <div className="buttonContent">
                                <img src = {linkedin}/>
                                </div></button></Link>
                            
                        <Link to ="https://github.com/nikolamilosevic86/verifAI" target="_blank" rel="noopener noreferrer"><button className="socialButton">
                            <div className="buttonContent">
                                <img src = {github}/>
                                </div></button></Link>
                        
                        
                    </div>

                  </div>
                  </div>
               </div>
               </div>
               </div>
               <div className="FooterDiv">
                    <Footer/>
                </div>

            
            
           
        </div>
        </div>
    )
}

export default ContactPage;