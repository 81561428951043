import { Link } from "react-router-dom";
import eu from "./eu.png";
import "./Footer.css";
import ngi from "./NGI_Search-logo.png"
function Footer()
{
    return (
        <div className="FooterContainer">
          <div className="FooterContent">
            <div className="FooterTextContainer">
                <p>Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or European Commission. Neither the European Union nor the granting authority can be held responsible for them. Funded within the framework of the NGI Search project under grant agreement No 101069364
                </p>

                <div className="ImagesContainer">
                  <Link className="imgLink" to ="https://www.ngi.eu/ngi-projects/ngi-search/" target="_blank" rel="noopener noreferrer"> 
                  <img className="NgiImage" src = {ngi}/>
                  
                  </Link>
                  <img className="EuImage" src = {eu}/>
            </div>
          </div>
          </div>
       
            
           
        </div>
    )
}

export default Footer;