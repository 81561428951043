import MenuBar from "./MenuBar";
import arrow from "./arrow.svg";
import minus from "./minus.svg";
import plus from "./plus.svg";
import "./FaqPage.css";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import DocumentMeta from 'react-document-meta';



const questions = [
    { id: 0, text: "What is VerifAI?", answer: "VerifAI is a question-answering system that uses artificial intelligence to provide referenced and verified answers to biomedical questions. As a knowledge based, VerifAI uses biomedical scientific literature, or more specifically abstracts of biomedical publications indexed in PubMed (https://pubmed.ncbi.nlm.nih.gov/) database. Using the combination of advanced search, information retrieval and fine-tuned large language models, VerifAI does the best attempt to provide answer to users’ question with references and verification that answer does not contain any hallucinations. ", categoryId: 0 },
    { id: 1, text: "How does VerifAI detect hallucinations?", answer: "VerifAI uses artificial intelligence to both answer the question and verify that the answer is not hallucinated. However, in order to do this, it uses two different models, that use different paradigm and different training methodology, ensuring independence between approaches. The model that verifies the answer, would highlight in green sentences that do not contain any hallucinations, in orange sentences that are not fully supported, gray would be sentences that do not have reference, and in red sentences that contradict the claim from the referenced abstract (this is extremely rare). ", categoryId: 0 },
    { id: 2, text: "What technology does the VerifAI Project use?", answer: "The project utilizes a large language model (Mistral 7B) along with Qdrant and OpenSearch indices to deliver accurate and verifiable answers to biomedical questions. The frontend is written in React.js, while backend is based on Python and FastAPI. ", categoryId: 0},
    { id: 3, text: "Who is behind VerifAI?", answer: "VerifAI is a system build in collaboration between Bayer Pharma R&D and Institute for Artificial Intelligence Research and Development of Serbia. The project is funded by Horizon Europe’s Next Generation Internet Search initiative. The team that worked on the project is multidisciplinary and talented managing to achieve the system you are looking at.", categoryId: 0 },
    { id: 4, text: "How can I access the VerifAI application?", answer: "You can access the VerifAI application by visiting app.verifai-project.com. Users can register and start asking questions to utilize the platform.", categoryId: 1 },
    { id: 5, text: "Is the code for the VerifAI Project available?", answer: "Yes, the entire codebase for the VerifAI Project is open-sourced and available on GitHub. You can find it at GitHub Repository.", categoryId: 1},
    { id: 6, text: "How can I contribute to the VerifAI Project?", answer: "Users are encouraged to contribute by providing feedback, suggesting improvements, or helping with development efforts. The project aims for sustainability and welcomes community involvement.", categoryId: 1 },
    { id: 7, text: "Are there any costs associated with using VerifAI?", answer: "Currently, the application is available for free, but its long-term availability may depend on future funding and sustainable hosting solutions. It costs us about 1600 USD/month to keep system running, and therefore we would welcome offers for sponsorships. ", categoryId: 2 },
   
];


const categories = [
    { id: 0, text: "Questions about the app:"},
    { id: 1, text: "Questions about access:"},
    { id: 2, text: "Questions about pricing:"},
    
];

function FaqPage()
{

   
    const [openQuestions, setOpenQuestions] = useState(
        questions.reduce((acc, question) => {
        acc[question.id] = 'fade-in';  
        return acc;
      }, {})
    ); 
    const [openQuestionList, setOpenQuestionList] = useState(
        categories.reduce((acc, category) => {
            acc[category.id] = 'fade-in';  
            return acc;
          }, {})
    ); 
    
    const [visibleQuestionList, setVisibleQuestionList] = useState(
        categories.reduce((acc, category) => {
          acc[category.id] = true;  
          return acc;
        }, {})
      );
    const [visibleQuestions, setVisibleQuestions] = useState(
        questions.reduce((acc, question) => {
            acc[question.id] = 'fade-in';  
            return acc;
          }, {})
    );

    
  


    useEffect(() => {
        document.title = "Verif.ai - Q&A";
      }, []);
      const meta = {
        title: "Verif.ai Q&A",
        description: "Verif.ai project Q&A",
        canonical: "https://verifai-project.com/q&a",
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
      }

      

    const toggleQuestion = (id) => {
        const isFadingOut = openQuestions[id] === 'fade-in';

      
        if (isFadingOut) {
            setOpenQuestions(prevState => ({
                ...prevState,
                [id]: 'fade-out'
            }));
        } else {
           
            setVisibleQuestions(prevState => ({
                ...prevState,
                [id]: true
            }));
            setOpenQuestions(prevState => ({
                ...prevState,
                [id]: 'fade-in'
            }));
        }
    };

    
    const toggleQuestionList = (id) => {
        const isFadingOut = openQuestionList[id] === 'fade-in';
        
      
        if (isFadingOut) {
            setOpenQuestionList(prevState => ({
                ...prevState,
                [id]: 'fade-out'
            }));
        } else {
           
            setVisibleQuestionList(prevState => ({
                ...prevState,
                [id]: true
            }));
            setOpenQuestionList(prevState => ({
                ...prevState,
                [id]: 'fade-in'
            }));
        }
    };

    const handleAnimationQuestionListEnd = (id) => {
     
        if (openQuestionList[id] === 'fade-out') {
            setVisibleQuestionList(prevState => ({
                ...prevState,
                [id]: false
            }));
        }
    };

    const handleAnimationQuestionsEnd = (id) => {
     
        if (openQuestions[id] === 'fade-out') {
            setVisibleQuestions(prevState => ({
                ...prevState,
                [id]: false
            }));
        }
    };


    return (
        <div className="PageBackground">
             <DocumentMeta {...meta} />
        <div className="FaqPageContainer">
           <div className="MenuBarDiv">
                <MenuBar/>
               </div>
            
            <div className="FaqPageContent">
                <div className="QuestionCategories">

                    {categories.map((category) => (
                   <div key={category.id} className="QuestionCategory">
                       
                       <button className="CategoryBtn" onClick={() => toggleQuestionList(category.id)}>
                       <div className="AlignButton">
                           <div className="CategoryBtnContainer">
                              
                               <p>{category.text}</p>
                               <img className="arrow" src={arrow}/>

                             
                             
                           </div>
                           </div>
                       </button>
                       
                       {openQuestionList[category.id] && (
                       <div style={{ display: visibleQuestionList[category.id] ? 'flex' : 'none' }}  className={`QuestionsList ${openQuestionList[category.id] || 'fade-out'}`} onAnimationEnd={() => handleAnimationQuestionListEnd(category.id)}>
                           <div className="Container">
                            {questions.filter((question) => question.categoryId === category.id).map((question) => (
                           <div key={question.id} className="QuestionAnswerContainer">
                               <div className="QuestionContainer" onClick={() => toggleQuestion(question.id)}>
                                   <img src = {openQuestions[question.id] === 'fade-in' ? minus: plus}/>
                                   <p>{question.text}</p>
                               </div>
                              {openQuestions[question.id] && ( <div style={{ display: visibleQuestions[question.id] ? 'flex' : 'none' }} className={`AnswerContainer ${openQuestions[question.id] || 'fade-out'}`} onAnimationEnd={() => handleAnimationQuestionsEnd(question.id)}>
                                   <p>{question.answer}</p>
                               </div> )}
                           </div>
                           ))}
                   
                           </div>

                       </div>
                       )}
                       
                   </div>
                   ))}
                </div>
            </div>
           

            <div className="FooterDiv">
                    <Footer/>
                </div>
            
           
        </div>
        </div>
    )
}

export default FaqPage;