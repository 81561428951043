import logo from './logo.svg';
import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutPage from './AboutPage';
import HomePage from './HomePage';
import SignInPage from './SignInPage';
import PublicationsPage from './PublicationsPage';
import TeamPage from './TeamPage';
import BlogPage from './BlogPage';
import FaqPage from './FaqPage';
import ContactPage from './ContactPage';
import SinglePost from './SinglePost';
import MemberPage from './MemberPage';

export const BACKEND = "https://api.verifai-project.com/";

function App() {
  return (
    <div className="App">
      
      <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/sign_in" element={<SignInPage />} />
            <Route path="/publications" element={<PublicationsPage />} />
           
            <Route path="/team" element={<TeamPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/q&a" element={<FaqPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/post" element={<SinglePost/>} />

            <Route path="/member" element={<MemberPage/>}/>
  
          </Routes>
        </Router>
        
    </div>
  );
}

export default App;
