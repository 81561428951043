import MenuBar from "./MenuBar";
import "./HomePage.css";
import Footer from "./Footer";
import verifai_logo from "./verifAI_App_cr.png";
import registration_screen from "./registration_screen.jpeg";
import anim3 from "./anim3.jpg";
import anim14 from "./anim14.jpg";
import anim13 from "./anim13.jpg";
import verif from "./verif.jpg";
import DocumentMeta from "react-document-meta";

import { useEffect, useState } from "react";

function HomePage()
{
    useEffect(() => {
        document.title = "Verif.ai - Home";
      }, []);
      const meta = {
        title: "Verif.ai Home",
        description: "Verif.ai project Home",
        canonical: "https://verifai-project.com/home",
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
      }

    const steps = [
        {"id": 0, "num": "Step One", "desc": "Create a profile"},
        {"id": 1, "num": "Step Two", "desc": "Set up configuration"},
        {"id": 2, "num": "Step Three", "desc": "Ask a question"},
        {"id": 3, "num": "Step Four", "desc": "Wait for an answer"},
        {"id": 4, "num": "Step Five", "desc": "Wait for verification of the answer"},
     

    ];

    const images = [
        {"id": 0, "image": registration_screen},
        {"id": 1, "image": anim3},
        {"id": 2, "image": anim14},
        {"id": 3, "image": anim13},
        {"id": 5, "image": verif}
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
          setFadeOut(true); // Start fading out
          setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            setFadeOut(false); 
          }, 250); 
        }, 5000); 
    
        return () => clearInterval(interval);
      }, [images.length]);

    return (
        <div className="PageBackground">
            <DocumentMeta {...meta} />
        <div className="HomePageContainer">
               <div className="MenuBarDiv">
                <MenuBar/>
               </div>
            
                <div className="ContentContainer">
                    <div class="boxes-container">
                   <div class = "home-box-left">
                    <h1>VerifAI CORE</h1>
                     <p>VerifAI CORE is an open-source solution for organizational and enterprize generative search with referenced and verifiable answers based on the indexed documents. It allows users to configure what to index, how, where, what large language model to use, and gives them full control over their data and users. It was made easy to deploy with your files, either home or in your organization. <a href="https://github.com/nikolamilosevic86/verifAI" target= "_blank" className="box_link">Check the GitHub to get started.</a>  </p>
                   </div>
                   <div class = "home-box-right">
                    <h1>VerifAI BIOMED</h1>
                    <p>VerifAI BIOMED is a deployed version of VerifAI core, indexing abstracts in biomedical domain (PubMed). Therefore, VerifAI BioMed can serve as educational, or decision-support engine for biology, chemistry, and medicine students and professionals. It is based on our self-hosted fine-tuned instance of Mistral model. You can register/login and try VerifAI BIOMED at <a href="https://app.verifai-project.com/" target="_blank" className="box_link">https://app.verifai-project.com/</a> </p>

                   </div>
                   </div>
                </div>
                <div className="HomeSlideShow">
                <div className="HomeSlideShowDiv">
                    <div className="StepsContainer">
                        <h1>{steps[currentIndex].num} </h1>
                        <h2>{steps[currentIndex].desc}</h2>


                    </div>
                    <div className="HomeImageContainer">

                        <img src = {images[currentIndex].image} className={fadeOut ? 'fade' : 'show'}/>

                    </div>
                </div>
                </div>
                <div className="FooterDiv">
                    <Footer/>
                </div>
        
            </div>
            </div>

           
    )
}

export default HomePage;